/**
 * スライダークラス
 * @constructor
 */

import Swiper from 'swiper';
class Slider {
  constructor() {
  }
  
  /**
   * スライダー
   */
  init() {
    let topKvSlider = new Swiper ('.js-topKvSlider', {
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      speed: 0,
      pagination: { 
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    // スライダー01
    let proDetailSliderThumb01 = new Swiper ('.js-sliderThumb01', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider01 = new Swiper ('.js-slider01', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb01
      },
    });

    // スライダー02
    let proDetailSliderThumb02 = new Swiper ('.js-sliderThumb02', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider02 = new Swiper ('.js-slider02', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb02
      },
    });

    // スライダー03
    let proDetailSliderThumb03 = new Swiper ('.js-sliderThumb03', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider03 = new Swiper ('.js-slider03', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb03
      },
    });

    // スライダー04
    let proDetailSliderThumb04 = new Swiper ('.js-sliderThumb04', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider04 = new Swiper ('.js-slider04', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb04
      },
    });

    // スライダー05
    let proDetailSliderThumb05 = new Swiper ('.js-sliderThumb05', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider05 = new Swiper ('.js-slider05', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb05
      },
    });

    // スライダー06
    let proDetailSliderThumb06 = new Swiper ('.js-sliderThumb06', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider06 = new Swiper ('.js-slider06', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb06
      },
    });

    // スライダー07
    let proDetailSliderThumb07 = new Swiper ('.js-sliderThumb07', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider07 = new Swiper ('.js-slider07', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb07
      },
    });

    // スライダー08
    let proDetailSliderThumb08 = new Swiper ('.js-sliderThumb08', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider08 = new Swiper ('.js-slider08', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb08
      },
    });
    
    // スライダー09
    let proDetailSliderThumb09 = new Swiper ('.js-sliderThumb09', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider09 = new Swiper ('.js-slider09', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb09
      },
    });

    // スライダー10
    let proDetailSliderThumb10 = new Swiper ('.js-sliderThumb10', {
      speed: 0,
      freeMode: true,
      watchSlidesProgress: true,
    });
    let proDetailSlider10 = new Swiper ('.js-slider10', {
      speed: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: proDetailSliderThumb10
      },
    });
  }
};

export default Slider;
