/**
 * アコーディオン
 * @constructor
 */
class Accordion {
  constructor() {
  }
  
  /**
   * アコーディオン
   */
  init() {
    $(function() {
      $('.js-accordion').on('click', function() {
        $(this).next().slideToggle();
        $(this).toggleClass('is-open');
      });
    });
  }
};

export default Accordion;
