/**
 * スマホのナビメニュークラス
 * @constructor
 */
 class Nav {
  constructor() {
  }
  
  /**
   * スマホのナビメニュー
   */
  init() {
    $(function() {
      var btnMenu = $('.js-btnMenu');
      var btnSearch = $('.js-btnSearch');
      var nav = $('.l-nav');
      btnMenu.on('click',function(){
        if (nav.hasClass('is-openMenu')) {
          nav.removeClass('is-openMenu');
          $('body').removeClass('is-fixed');
        } else {
          nav.addClass('is-openMenu');
          $('body').addClass('is-fixed');
          if (nav.hasClass('is-openSearch')) {
            nav.removeClass('is-openSearch');
          }
        }
      });
      btnSearch.on('click',function(){
        if (nav.hasClass('is-openSearch')) {
          nav.removeClass('is-openSearch');
          $('body').removeClass('is-fixed');
        } else {
          nav.addClass('is-openSearch');
          $('body').addClass('is-fixed');
          if (nav.hasClass('is-openMenu')) {
            nav.removeClass('is-openMenu');
          }
        }
      });
      $('.l-nav__contents a[href]').on('click', function() {
        if (nav.hasClass('is-openMenu')) {
          nav.removeClass('is-openMenu');
        } else if (nav.hasClass('is-openSearch')) {
          nav.removeClass('is-openSearch');
        }
        $('body').removeClass('is-fixed');
      });
    });
  }
};

export default Nav;
