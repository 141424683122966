/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
// import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';
import 'modaal';
import SimpleBar from "simplebar";

/**
 * import modules
 */
import Nav from './modules/nav';
import Common from './modules/common';
import Accordion from './modules/accordion';
import Slider from './modules/slider';
import Tab from './modules/tab';
import Form from './modules/form';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    const nav = new Nav();
    nav.init();

    const common = new Common();
    common.init();

    const accordion = new Accordion();
    accordion.init();

    const slider = new Slider();
    slider.init();

    const tab = new Tab();
    tab.init();

    const form = new Form();
    form.init();

  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {


    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();

    this.init_simpleBar();

    this.init_modaal('.js-modalImg');
  }

  init_simpleBar(){
    if ( $('#myElement').length == 0 ){
        return
    }
    new SimpleBar(document.getElementById('myElement'), {
        autoHide: false
    });
}

  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }

  /**
   * ライブラリ/modaal
   */
     init_modaal(target) {
      $(target).modaal({
        type: 'image'
      });
    }
}

export default new Main();
