/**
 * アコーディオン
 * @constructor
 */
class Form {
  constructor() {
  }
  
  /**
   * アコーディオン
   */
  init() {
    $(function() {
      var formField01 = function() {
        // console.log($('.js-formField-trigger')[0].checked);
        if($('.js-formField-trigger')[2].checked) {
          $('.js-formField-target01').show();
          $('.js-formField-target01 .m-inputTxt').prop('disabled', false);
          $('.js-form .m-form__item:visible:even').css('background-color','#fff');
          $('.js-form .m-form__item:visible:odd').css('background-color','#f5f5f5');
        } else {
          $('.js-formField-target01').hide();
          $('.js-formField-target01 .m-inputTxt').prop('disabled', true);
          $('.js-form .m-form__item:visible:even').css('background-color','#fff');
          $('.js-form .m-form__item:visible:odd').css('background-color','#f5f5f5');
        }
      }
      formField01();
      $('.js-formField-trigger').on('click', function() {
        formField01();
      });
    });
  }
};

export default Form;
